
.et-btn-drpdown{
    .et-btn{
        background-color: #414141;
        border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
        color: #999999;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        .notify{
            border-radius: 4px;
            background-color: #B94A48;
            color: #FFFFFF;
            font-weight: bold;
            font-size: 11px;
            vertical-align: middle;
            padding: 2px;
            line-height: 16px;
            margin-left: 4px;
        }
    }
    .et-btn:hover{
        color: #ffffff;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    }
    .drpdown-content{
        display: block;
        position: absolute;
        min-width: 10rem;
        .triangle-pointer{
            width:0;
            height: 0;
            margin-top:2px;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 8px solid #ffffff;
        }
        .drpdown-list{
            display: block;
            position: relative;
            border: 1px solid rgba(0, 0, 0, 0.2);
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
            border-radius: 4px;
            background-color: #ffffff;
            padding: 0.625rem 0 0.625rem 0;
            z-index: 1000;
            margin: 0;
            left: -13px;
            li{
                list-style: none;
                cursor: pointer;
                text-align: left;
                padding: 0.313rem 0.938rem 0 0.938rem; 
                color: #333333;             
            }
            li:hover{
                background-color: #333333;
                color:#ffffff;
            }
        }
    }
}