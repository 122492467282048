.MuiPaper-root{
    .modal-item{
        display: list-item;
    }
    .info{
        color: #3699FF;
    }
    .error{
        color: #f64e60;
    }
}