.et-accordion{
    .accordion-header{
        background-color: #3a3a57;
        color: #ffffff;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }
    .accordion-body{
        padding: 0.625rem 0.938rem 0.625rem 0.938rem;
    }
}