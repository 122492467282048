// metronic override
.header{
    .et-date-time{
        color: #B5B5C3;
    }
    @media(max-width: 991.98px){
        .et-date-time{
            display: none !important;
        }
        .topbar{
            .et-date-time{
                display: flex !important;
            }
        }
    }
    @media(min-width: 991.99px){
        .topbar{
            .et-date-time{
                display: none !important;
            }
        }
    }
    .header-drop-content{
        box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
        background-color: #fff;
    }
}

// logo desktop
.brand-logo{
    img{
        height: 27px;
        padding-left: 10px;
    }
}
// logo tab and mobile
.header-mobile {
    a{
        img{
            height: 27px;
        }
    }
}
