
.sub-heading {
        background: #E8E8E8;
        font-size: 14px;
        font-weight: normal;
        margin-left: 20px;
        margin-bottom: 20px;
        padding: 4px !important;
        line-height: 15px !important;
        display: block;
    }
.mtech-form{
    display: none;
}
.show{
    display: block;
}