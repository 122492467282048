.et-content-layout{
    width:100%;
    background: none 0px 0px repeat scroll rgb(249, 249, 249);
    border-width: 1px;
    border-style: solid;
    border-color: rgb(205, 205, 205);
    min-height: 20vh;
    .layout-content{
        padding: 0.75rem 0.938rem;
        background: rgb(255, 255, 255);
    }
    .section-header-text{
        padding-left: 0.75rem;
    }
    .btn-group-icon{
        span{
            cursor: pointer;
        }
    }
    .content-layout-btn-drop{
        position: absolute;
        top:40px;
        right:40px;
        box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
        background-color: #fff;
        min-width: 10rem;
        min-height: 2rem;
        padding: 0.25rem !important;
        z-index: 1;
        text-align: left;
    }
}