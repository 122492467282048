.et-breadcrumb{
    display: flex;
    align-items: center;
    width:100%;
    .bread-home-icon{
        padding-right:0.75rem;
    }
    .breadcrumb-item{
        text-transform: capitalize;
        color: #a9a9a9;
        font-size: 12px;
    }
    .breadcrumb-item:hover{
        text-transform: capitalize;
        color: #3699FF;
        cursor: pointer;
    }
    .breadcrumb-last:hover{
        color: #464E5F;
        font-weight: bold;
        cursor: default;
    }
    .bread-crumb-seperator{
        color: #B5B5C3;
        font-size: 6px;
        width: 3em;
    }
}