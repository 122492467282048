
.container{
    padding: 0 0.938rem;
}
.form-control{
    padding: 0rem 1rem;
    max-height: 2.5rem;
}
.btn-link{
    text-align: left;
}