.et-sub-heading{
    background: #3a3a57;
    font-size: 17px;
    color: #fff;
    margin: 0.982rem 0 0.982rem 0;
    padding: 0.625rem;
    font-size: 13px;
    border-radius: 5px;
    span{
        font-size: 11px;
    }
}