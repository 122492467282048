.widget{
    padding: 0.75rem 0.625rem;
    text-align: center;
    background-color: #ffffff;
    cursor: pointer;
    border-radius: 40px;
    a{
        color: inherit;
    }
}
.widget:hover{
    background: linear-gradient(rgb(251, 251, 251) 0%, #e1f0ff 100%);
    text-decoration: underline;
    color: #005580;
}