.et-checkbox{
    display: flex;
    align-items: baseline;
}
td{
    .et-checkbox{
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0;
        input{
            margin: 0;
            height: calc(1.5em + 1.3rem + 2px);
        }
    }
}