.switch-page{
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 2rem;
    .switch-icons{
        span{
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            margin-left: 0.5rem;
            margin-right: 0.5rem;
            border-radius: 10px;
        }
        .visited{
            background-color:#1BC5BD;
            color: #fff;
        }
        .current{
            background-color: #3783e7;
            color: #fff;
        }
        .page-circl-icon{
            cursor: pointer;
        }
        padding-top: 0.938rem;
        padding-bottom: 0.938rem;
    }
    .switch-btns{
        display:flex;
        justify-content: center;
        div{
            padding-right: 0.938rem;
        }
    }
}