.table{
    border: 1.5px solid #ECF0F3;
    input{
        width:100%
    }
    thead{
        background-color: #464E5F;
        color: #ffffff;
    }
    th, td{
        text-align: left;
        border-right: 1.5px solid #ECF0F3;
        border-bottom: 1.5px solid #ECF0F3;
        vertical-align: middle;
    }
}