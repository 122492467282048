.et-loader{
   margin-left:-10px ;
   margin-right: -10px;
   margin-top: -15px;
   margin-bottom: 15px;
}
.et-spinner{
    position: fixed;
    width: 80%;
    top: 0px;
    height: 100vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20rem;
    z-index: 99;
    background: rgba(0, 0, 0, 0.1);
}
.text-bold{
    font-weight: 600 !important;
}
.widget-container{
    padding-bottom: 0.75rem;
    padding-right: 0.625rem
}
.border-red{
    border-color: #F64E60;
}
hr{
    margin-top:2rem;
    margin-bottom: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
}
.input-container{
    padding: 0 0.75rem 0.75rem 0.75rem;
    label{
        margin:0;
        text-transform: uppercase;
    }
    input[type=checkbox]{
        margin-right: 0.313rem;
    }
    textarea{
        max-height: none;
    }
    .asterisk{
        color: #f64e60;
    }
}
.form-sub-heading{
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    padding: 0 0.625rem 0.982rem 0.625rem;
    .input-container{
        padding: 0;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

//react date picker override
.react-datepicker-wrapper{
    width: 100%;
}
