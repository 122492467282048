.crop-input-container{
    display:flex;
    flex-direction: column;
    .crop-file-upload{
        input[type="file"] {
            display: none;
        }
        border: 1px solid #ccc;
        display: inline-block;
        padding: 6px 12px;
        cursor: pointer;
        text-transform: capitalize;
    }
}

